<template>
  <div class="mt-40">
    <div class="custom-form__button-container">
      <ButtonComponent @click="addStage" mod="gradient-bg">Добавить этап</ButtonComponent>
      <ButtonComponent @click="selectStage">Выбрать этап</ButtonComponent>
    </div>
    <div v-if="card.stages" class="mt-40">
      <div class="table-container mb-16 js-table-arrow">
        <table class="stockTable">
          <thead>
            <tr>
              <th>Название</th>
              <th>Время</th>
              <th>Стоимость, руб.</th>
              <th>Необязательно</th>
              <th>Параллельно</th>
              <th
                :style="{
                  textAlign: 'center',
                }"
              >
                Действия
              </th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody v-if="flatList.length">
            <tr
              v-for="(item, index) in flatList"
              :key="index"
              :class="`editStepScenarioTap__tr--${SCENARIOS_NODE_KIND_CLASS[item.nodeKind]}`"
            >
              <td :class="getItemClass(item)">
                <div :title="item.name">
                  <i
                    v-if="item.nodeKind === SCENARIOS_NODE_KIND.procedure"
                    class="fa fa-cog"
                    style="position: absolute; left: 16px; top: 32px"
                  ></i>
                  <i
                    v-if="item.nodeKind === SCENARIOS_NODE_KIND.step"
                    class="fas fa-walking"
                    style="position: absolute; left: 40px; top: 32px"
                  ></i>
                  <ComponentInput v-model="item.name" :disabled="!!item.id || !item.isNew"></ComponentInput>
                </div>
              </td>
              <td>
                {{ `${item.duration ?? 0} дн. ` }}
                <template v-if="item.hour"> {{ item.hour }} ч. </template>
                <template v-if="item.minute"> {{ item.minute }} мин. </template>
              </td>
              <td>
                {{ item.cost ?? 0 }}
              </td>
              <td class="checkbox">
                <div v-if="item.nodeKind !== SCENARIOS_NODE_KIND.stage" class="checkboxInput">
                  <input
                    v-model="item.notNecessary"
                    type="checkbox"
                    @input="changeCheckbox"
                    :id="`necessary${item.checkboxId}${item.id}${item.created}`"
                  />
                  <label
                    :for="`necessary${item.checkboxId}${item.id}${item.created}`"
                    class="custom-form__label"
                  ></label>
                </div>
              </td>
              <td>
                <div v-if="item.nodeKind !== SCENARIOS_NODE_KIND.stage" class="checkboxInput">
                  <input
                    v-model="item.parallel"
                    type="checkbox"
                    @input="changeCheckbox"
                    :id="`parallel${item.checkboxId}${item.id}${item.created}`"
                  />
                  <label
                    :for="`parallel${item.checkboxId}${item.id}${item.created}`"
                    class="custom-form__label"
                  ></label>
                </div>
              </td>
              <td class="item-actions">
                <div class="item-actions__item" v-if="item.nodeKind === SCENARIOS_NODE_KIND.stage">
                  <a href="javascript:" @click="addProcedure(item)">Добавить процедуру</a>
                  <a href="javascript:" @click="selectProcedure(item)">Выбрать процедуру</a>
                </div>
                <div class="item-actions__item" v-if="item.nodeKind === SCENARIOS_NODE_KIND.procedure">
                  <a href="javascript:" @click="addStep(item)">Добавить операцию</a>
                  <a href="javascript:" @click="selectStep(item)">Выбрать операцию</a>
                </div>
                <a v-if="item.nodeKind === SCENARIOS_NODE_KIND.step" href="javascript:" @click="setupStep(item)"
                  >Настроить операцию</a
                >
              </td>
              <td>
                <IconComponent @click="deleteItem(item)" name="delete" />
              </td>
              <td>
                <IconComponent
                  :class="{ 'icon-disabled': checkDisabled(item, true) }"
                  @click="move(item, true)"
                  title="Поместить выше"
                  name="arrow_up"
                />
                <IconComponent
                  :class="{ 'icon-disabled': checkDisabled(item, false) }"
                  @click="move(item, false)"
                  title="Поместить ниже"
                  name="arrow_down"
                />
              </td>
              <td></td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>Итого:</td>
              <td>{{ card.duration }}</td>
              <td>{{ card.cost }}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>

  <ModalComponent
    v-model="stepModal"
    content-class="modal--new"
    headType="redesigned"
    :width="950"
    title="Настройка операции"
    @close="closeEditStepModal"
  >
    <EditStepModal :step-data="currentStep" @updateStep="updateStep" @close="closeEditStepModal" />
  </ModalComponent>
</template>

<script>
  // import moment from 'moment';

  import ComponentInput from '@/common/components/ComponentInput';
  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
  import IconComponent from '@/common/ui/IconComponent';

  import ModalComponent from '@/components/modals/ModalComponent';

  import { SCENARIOS_NODE_KIND, SCENARIOS_NODE_KIND_CLASS } from '../../utils/constants';
  import EditStepModal from './EditStepModal';

  export default {
    components: {
      ButtonComponent,
      IconComponent,
      ComponentInput,
      ModalComponent,
      EditStepModal,
    },
    emits: [
      'addStage',
      'selectStage',
      'changeCheckbox',
      'addProcedure',
      'selectProcedure',
      'addStep',
      'selectStep',
      'deleteItem',
      'checkDisabled',
      'move',
      'recalcExpenses',
    ],
    props: {
      flatList: {
        type: Array,
        required: true,
      },
      card: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        currentStep: null,
        stepModal: false,
        SCENARIOS_NODE_KIND,
        SCENARIOS_NODE_KIND_CLASS,
      };
    },
    methods: {
      addStage() {
        this.$emit('addStage');
      },
      selectStage() {
        this.$emit('selectStage');
      },
      changeCheckbox(value) {
        this.$emit('changeCheckbox', value);
      },
      addProcedure(item) {
        this.$emit('addProcedure', item);
      },
      selectProcedure(item) {
        this.$emit('selectProcedure', item);
      },
      addStep(item) {
        this.$emit('addStep', item);
      },
      selectStep(item) {
        this.$emit('selectStep', item);
      },
      deleteItem(item) {
        this.$emit('deleteItem', item);
      },

      checkDisabled(item, isUp) {
        const itemCollection = this.getParentCollection(item);

        if (itemCollection) {
          const itemIndex = itemCollection.indexOf(item);
          return (itemIndex == 0 && isUp) || (itemIndex == itemCollection.length - 1 && !isUp);
        }

        return false;
      },
      getParentCollection(item) {
        switch (item.nodeKind) {
          case SCENARIOS_NODE_KIND.stage:
            return this.card.stages;
          case SCENARIOS_NODE_KIND.procedure:
            return item.parent?.procedures;
          case SCENARIOS_NODE_KIND.step:
            return item.parent?.steps;
          default:
            return null;
        }
      },
      move(item, isUp) {
        if (this.checkDisabled(item, isUp)) return;

        const itemCollection = this.getParentCollection(item);
        if (itemCollection) {
          let itemIndex = itemCollection.indexOf(item);
          itemCollection.splice(itemIndex, 1);
          isUp ? itemIndex-- : itemIndex++;
          itemCollection.splice(itemIndex, 0, item);
        }
      },
      getItemClass(item) {
        return 'item-name item-level-' + item.nodeKind;
      },
      setupStep(step) {
        this.currentStep = step;
        this.stepModal = true;
      },
      updateStep(stepDto) {
        this.currentStep.stepParticipants = stepDto.stepParticipants;
        this.currentStep.stepResultDocumentIds = stepDto.stepResultDocumentIds;
        this.currentStep.stepRequiredDocumentIds = stepDto.stepRequiredDocumentIds;
        this.currentStep.stepLegalSourceIds = stepDto.stepLegalSourceIds;
        this.currentStep.stepActions = stepDto.stepActions;
        this.currentStep.serviceUrl = stepDto.serviceUrl;
        this.currentStep.isHourFormat = stepDto.isHourFormat;
        this.currentStep.durationMins = stepDto.durationMins;
        this.currentStep.duration = stepDto.duration;
        this.currentStep.hour = stepDto.hour;
        this.currentStep.minute = stepDto.minute;
        this.currentStep.cost = stepDto.cost;
        this.currentStep.durationComment = stepDto.durationComment;
        this.currentStep.costComment = stepDto.costComment;
        this.currentStep.extraInfo = stepDto.extraInfo;
        this.currentStep.templateId = stepDto.templateId;
        this.closeEditStepModal();
        this.$emit('recalcExpenses');
      },
      closeEditStepModal() {
        this.stepModal = false;
      },
      // getDurationItem(item) {
      //   switch (item.nodeKind) {
      //     case 0:
      //       return `${moment.duration(item.duration).asDays()} дн.`;
      //     case 1:
      //       return `${item.duration.split('.')[0].toString()} дн.`;
      //     default:
      //       return `${item.duration ? item.duration : 0} дн.`;
      //   }
      // },
    },
    // computed: {
    //   getDurationTotal() {
    //     return moment.duration(this.card.durationTotal).asDays();
    //   },
    // },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/theme/default/custom-form.scss';

  .editStepScenarioTap__tr {
    &--stage {
      td {
        background-color: $grey;
        font-weight: 800;
      }
    }
    &--procedure {
      td {
        font-weight: 600;
      }
    }
    &--step {
      td {
        font-weight: 400;
      }
    }
  }

  .icon-disabled {
    opacity: 0.4;
  }
  .item-name {
    width: 50%;
  }
  .item-level-0 {
  }
  .item-level-1 {
    padding-left: 40px !important;
    position: relative;
  }
  .item-level-2 {
    padding-left: 60px !important;
    position: relative;
  }
  .item-actions {
    white-space: nowrap;

    &__item {
      display: flex;
      flex-direction: column;
      gap: 4px;
      justify-content: start;
    }
  }
  .stockTable {
    tfoot {
      td {
        padding: 16px;
      }
    }
  }
  .table-container table.stockTable thead th {
    &:nth-of-type(1) {
      width: 15%;
    }
    &:nth-of-type(2) {
      width: 20%;
    }
    &:nth-of-type(3) {
      width: 20%;
    }
  }
</style>
